@font-face {
    font-family: 'avenirnextregular';
    src: url('../../main/assets/fonts/avenirnextregular.eot');
    src: url('../../main/assets/fonts/avenirnextregular.eot') format('embedded-opentype'),
         url('../../main/assets/fonts/avenirnextregular.woff2') format('woff2'),
         url('../../main/assets/fonts/avenirnextregular.woff') format('woff'),
         url('../../main/assets/fonts/avenirnextregular.ttf') format('truetype'),
         url('../../main/assets/fonts/avenirnextregular.svg#avenirnextregular') format('svg');
}

@font-face {
    font-family: ITCAvantGardeStd;
    font-weight: bold;
    src: url("../../main/assets/fonts/ITCAvantGardeStd-Bold.otf") format("opentype");
}

/*
To customize the look and feel of Ionic, you can override the variables
in ionic's _variables.scss file.

For example, you might change some of the default colors:
*/

$light:                           #fff !default;
$lightPink:                       #fde7ef !default;
$stable:                          #f8f8f8 !default;
$positive:                        #4a87ee !default;
$calm:                            #43cee6 !default;
$balanced:                        #66cc33 !default;
$energized:                       #f0b840 !default;
$assertive:                       #ef4e3a !default;
$royal:                           #9b1b3b !default; //color: cabaret
$dark:                            #444 !default;

// The path for our ionicons font files, relative to the built & temporary main.css
$ionicons-font-path: "../assets/fonts" !default;

// Include all of Ionic
@import "../../bower_components/ionic/scss/ionic";



.splash_background{
  background : -moz-radial-gradient(50% 50%, circle farthest-corner, rgba(180, 0, 79, 1) 0%, rgba(109, 28, 48, 1) 99.88%);
  background : -webkit-radial-gradient(50% 50%, circle farthest-corner, rgba(180, 0, 79, 1) 0%, rgba(109, 28, 48, 1) 99.88%);
  background : -webkit-gradient(radial,50% 50% ,0 , 50% 50%, 400 ,color-stop(0,rgba(180, 0, 79, 1) ),color-stop(0.9988,rgba(109, 28, 48, 1) ));
  background : -o-radial-gradient(50% 50%, circle farthest-corner, rgba(180, 0, 79, 1) 0%, rgba(109, 28, 48, 1) 99.88%);
  background : -ms-radial-gradient(50% 50%, circle farthest-corner, rgba(180, 0, 79, 1) 0%, rgba(109, 28, 48, 1) 99.88%);
  position : absolute;
  left : 0px;
  top : 0px;
  width : 100%;
  height : 100%;
  filter: progid:DXImageTransform.Microsoft.Alpha(Stlye=2);
  overflow: hidden;
}

.right-buttons{ 
  right: 20px !important; 
  position: relative !important; 
} 

@-webkit-keyframes increase_size {
  0% {
    transform: scale(0, 0);

  }
  100% {
    transform: scale(10, 10);
  }
}

@-webkit-keyframes fadein {
    0%    {
    opacity: 0;
    }
    70%   {
    opacity: 0;
    }
    100%   {
    opacity: 1;
    }
}

@-webkit-keyframes fadeinlow {
    0%    {
    opacity: 0;
    }
    70%   {
    opacity: 0;
    }
    100%   {
    opacity: 0.4;
    }
}

.circle{
  height:48vw;
  width:48vw;
  border: 5vw solid white;
  border-radius: 25vw;
  position:absolute;
  top: 50%;
  left: 50%;
  margin-top: -25vw; /* Half the height */
  margin-left: -25vw; /* Half the width */
  z-index:10;
  -webkit-animation: 2s linear 0s 1 increase_size;

  overflow: hidden;

  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    animation-fill-mode: forwards;
}


.splash_main_text{

  font-size:11.62px;
  line-height:14.5px;

  font-size:calc(8.5px + 1.7vw);
  line-height:calc(9px + 1.7vw);

  position:absolute;
  width:100%;
  top:34%;
  margin-top:2vh;

  text-align: center;
  font-family: 'ITCAvantGardeStd' !important;
  font-weight:500;
  color:white !important;
  -webkit-animation: 2s ease-out 0s 1 fadein;
}

.eu.splash_main_text{
  top: 58% !important;
}

.splash_top_text{
  font-size: 11.62px;
  line-height: 14.5px;
  font-size: calc(8.5px + 1.7vw);
  line-height: calc(9px + 1.7vw);
  position: absolute;
  width: 100%;
  margin-top: 1.4vh;
  text-align: center;
  font-family: 'ITCAvantGardeStd' !important;
  font-weight: 500;
  color: white !important;
  -webkit-animation: 2s ease-out 0s 1 fadein;
}

.splash_main_sponsor_pic{
  position: relative;
}
.splash_main_sponsor_text{
  width: inherit;
  height: inherit;
}

.splash_main_sub_text{
  font-size:12px;
  line-height:13px;

  font-size:calc(5px + 1.6vw);
  line-height:calc(7px + 1.6vw);

  position:absolute;
  width:100%;
  top:41vh;
  margin-top:2vh;

  text-align: center;
  font-family: 'ITCAvantGardeStd' !important;
  font-weight:500;
  color:white !important;
  -webkit-animation: 2s ease-out 0s 1 fadein;
}

.eu.splash_main_sub_text{
  top:69% !important;
}


.splash-incyte{
  margin-left: 3vw;
  position:relative;
  display: block;
  width:73.6px;
  width:14vh;

}

.eu.splash-incyte{
  margin:auto !important;
  margin-top:22.4px !important;
  margin-top:3.5vh !important;
}

.splash-novartis{
  position:relative;
  display: block;
  width:96px;
  width:15vh;
}
.splash-daiichi{
  width:24vw;
}

.splash-abbvie{
  position:relative;
  display: block;
  margin-top: -1.3vh;
  width:105px;
  width:16.5vh;
}
.splash-shire{
  margin-top: 10px;
  margin-left: -1vw;

  position:relative;
  width:75px;
  width:12vh;
}

.splash-celgene{
  position:relative;
  display: block;
  top: -1vh;
  margin-left: 2vw;
  width:73.6px;
  width:14vh;
}

.splash-takeda{
  position:relative;
  display: block;
  margin-left: 2vw;
  width:73.6px;
  width:14vh;
}

.splash-pfizer{
  top: -3vh;
  position:relative;
  width: 73.6px;
  width: 13.5vh;
}

.splash-bristol{
  position: relative;
  display: block;
  width: 102.5px;
  width: 16.4vh;
}

.splash_main_disclaimer{
  opacity: 0.4;
  font-size:8.4px;
  font-size:calc(3px + 1.5vw);

  line-height:10.4px;
  line-height:calc(5px + 1.5vw);

  position:absolute;
  width:100%;
  bottom:20px;
  text-align: center;
  font-family: 'ITCAvantGardeStd'  !important;
  font-weight:500;
  color:white !important;
  -webkit-animation: 2s ease-out 0s 1 fadeinlow;
}

.splash_main_circle{
  width:180px;
  width:50vw;

  height:180px;
  height:50vw;

  border: 36px solid white;
  border: 10vw solid white;

  border-radius: 90px;
  border-radius: 25vw;

  position:absolute;
  top: 24%;
  left: 50%;

  margin-top: -90px;
  margin-top: -32vw;

  margin-left: -90px;
  margin-left: -25vw;


  z-index:10;
  overflow: hidden;
  -webkit-animation: 2s ease-out 0s 1 fadein;
}

.eu.splash_main_circle{
  width:198px !important;
  width:55vw !important;

  height:198px !important;
  height:55vw !important;

  border: 36px solid white !important;
  border: 10vw solid white !important;

  border-radius: 99px !important;
  border-radius: 27.5vw !important;

  position:absolute !important;

  top: 108px !important;
  top: 30vh !important;

  margin-top: -99px !important;
  margin-top: -27.5vw !important;

  margin-left: -99px !important;
  margin-left: -27.5vw !important;
}



// Header of each page
.nav-bar-title {
  text-transform: uppercase;
  font-size: .8em;
}

.background, .background .pane, .background .item, .background .item-complex .item-content {
    background-color: white;
}

.spinner{
  position:absolute;
   top: 50%;
   left: 50%;
   width: 28px;
   height: 28px;
   margin-top: -14px; /* Half the height */
   margin-left: -14px; /* Half the width */
   z-index:10;
}

#mainlist .item {
 color:#222;
  padding-right:10% !important;
}
.item.item-stable {
  color:#222;
}

.splash{
  overflow: hidden;
  background-color:#81053a !important;
}

.nhl_img{
  width:100%;
  height:100%;
}

.splash_img{
  overflow: hidden;
  position:fixed;
  z-index:5;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
}

.splash_img.ng-enter {
  transition:0.5s linear all;
  opacity:0;
}
.splash_img.ng-enter.ng-enter-active {
  opacity:1;
}
.splash_img.ng-leave {
  transition:0.5s linear all;
  opacity:1;
}
.splash_img.ng-leave.ng-leave-active {
  opacity:0;
}


.flex-row{
  display: flex;
  justify-content: center;
}

.flex-item{
  width:16vh;
  height:15vh;
  text-align:center;
  margin:4px;

  color:white;

  display: table;

  -moz-box-shadow:    3px 3px 3px 3px #ccc;
  -webkit-box-shadow: 3px 3px 3px 3px #ccc;
  box-shadow:         3px 3px 3px 3px #ccc;
}

.flex-item2{
  width:16vh;
  height:15vh;
  text-align:center;
  margin:10px;
  border-radius: 5px;
  color:white;

   display: table;

  -moz-box-shadow:    3px 3px 3px 3px #ccc;
  -webkit-box-shadow: 3px 3px 3px 3px #ccc;
  box-shadow:         3px 3px 3px 3px #ccc;
}

.flex-item2 p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.flex-item p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.flex-box{
  position: relative;
  /*
  padding-top:calc(100vh - 105px);
  transform: translateY(-50%);
  */
  padding-top:15px;
}

.tabs-striped.tabs-light .tab-item.tab-item-active, .tabs-striped.tabs-light .tab-item.active, .tabs-striped.tabs-light .tab-item.activated{
  color: #9b1b3b;
  border-color: #9b1b3b;
}

.menu-icon.ion-chevron-right{
position:absolute;
  top: 50%;
  height: 20px;
  margin-top: -10px;
  right:18px;

}

.clear-button {
  width:40px;
  height:20px;
  z-index: 10;
  position: absolute;
  margin-top: -25px;
  right:-4px;
}

.popup-buttons .button {

    background-color: #9b1b3b;
    color: white;
    font-size: 16px;

}

.platform-android .buttons-right  {
  width:50px;
}

.test {
  margin:10px
}

ul li{
  list-style-type: disc !important;
  margin-left:1.6em;
}

ol li {
  list-style-type: decimal !important;
  margin-left:1.6em;
}

ol ul li{
  list-style-type: disc !important;
  margin-left:1.6em;
}

.list-style-type-circle{
  li {
    list-style-type: circle !important;
  }
}

.list-style-type-latin {
  li {
    list-style-type: lower-latin !important;
  }
}

.list-style-type-square {
  li {
    list-style-type: square !important;
  }
}
.list-style-type-disc{
  li {
    list-style-type: disc !important;
  }
}

.single-line-title {
  color : white;
}

.first-line-title {
  color : white;
  display: block;
  line-height: .8em;
  font-size: .72em;
  margin: 9.5px 0;
}

.second-line-title {
  color : white;
  display: block;
  line-height: .8em;
  font-size: .72em;
  margin: 1px 0;
}

.triple-first-line-title {
  color : white;
  display: block;
  line-height: .3em;
  font-size: .72em;
  margin: 9.5px 0;
}

.triple-second-line-title {
  color : white;
  display: block;
  line-height: .3em;
  font-size: .72em;
  margin: 1px 0;
}
.triple-third-line-title {
  color : white;
  display: block;
  line-height: 1.8em;
  font-size: .72em;
  margin: 1px 0;
}

.tabs .tab-item i:nth-of-type(2) {
  display: none;
}

.green{
  background-color: #88C100;
  color: white;
}

.yellow{
  background-color: #e8e840;
}

.red{
color: white;
  background-color: #9b1b3b;
}

.orange{
color: white;
  background-color: #E87E04;
}

.platform-ios {
  .title.title-center.header-item {
    left: 49px!important;
    right: 49px!important;
  }
}

.platform-android {
  .title.title-left.header-item {
    right: 68px!important;
  }
  //.bar-subheader{
  //  top:93px !important;
  //}
}

.row, .scroll {
  height: 100%;
}

* {
  font-family: 'arial' !important;
}

// Main menu list structure
.menu-list-item {
  /*font-weight:bold;*/
  /*text-transform: uppercase;*/
  font-size: 15px;
  color: #222;
  /*color:black;*/
  .menu-icon {
    font-size: 0.7em;
    float: right;
  }
}

.tab-item.tab-item-active, .tab-item.active, .tab-item.activated {
    color: #9b1b3b;
}

// Main searchbar
.hem-searchbar {
  background: #E0DEDF;
  color: black;
  height: 34px;
}

.page-title{

}

#disclaimer-container{
  margin:22px;
}

#disclaimer-logo img{

  display: block;
  margin-left: auto;
  margin-right: auto;
  width:50vw;
  max-width:340px;
}
#disclaimer-sponsors img{

  display: block;
  margin-left: auto;
  margin-right: auto;
  width:37vw;
  max-width:340px;
}


.hem-radio-button {

  // do not use the checkmark for checking
  i {
    display: none !important;
  }

  .score-radio-points {
    position: absolute;
    top:0;
    right: 0;
    padding: 16px;
    color: darkgray;
  }

  input:checked + .radio-content .item-content {
    /* style the item content when its checked */
    background: #fde7ef;
  }

}

.button-bar-radio {

  @extend .hem-radio-button;
  @extend .button-bar;

  .radio-content {
    width: 100% !important;

  }


}

.item.page-layout {
  border-color: #ffffff;
}

.item.item-divider{
  background-color: #fde7ef !important;
}

.lightPink{
  background-color: #fde7ef !important;
  td{
    background-color: #fde7ef !important;
  }
}

.item.item-divider.input-header{
  background-color: #9b1b3b !important;
  color: white;
}

ion-scroll .item.item-text-wrap{
  border-color: #ffffff;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
}

.item.item-text-wrap{
  background-color: #ffffff;
  font-weight: light;

}

td, th {
  font-size: 85%;
  border: 1px solid gray;
  background-color: #ffffff;
  margin:2px;
  padding-left:10px;
  padding-right:10px;
  padding-top:5px;
  padding-bottom:5px;
}


.back-text{
  display:none !important;
}


/*.titlecell {
    border-collapse: collapse;
    border-style: hidden;
}*/

td.titlecell {
  background-color: #9b1b3b !important;
  color:white  !important;
  border: 1px solid white;
   border-top: 1px solid #9b1b3b;
   border-bottom: 1px solid white;

}
th.titlecell {
  background-color: #9b1b3b !important;
  color:white  !important;
  border: 1px solid white;
  border-top: 1px solid #9b1b3b;
   border-bottom: 1px solid white;
}

table tr td.titlecell:first-child,
table tr th.titlecell:first-child {
  border-top: 1px solid #9b1b3b;
  border-left: 1px solid #9b1b3b;
}
table tr td.titlecell:last-child,
table tr th.titlecell:last-child {
  border-top: 1px solid #9b1b3b;
  border-right: 1px solid #9b1b3b;

}

/*tr td.titlecell:nth-child(even) {background-color: #f2f2f2 !important}*/

/*td.titlecell, th.titlecell  {
    border: 1px solid black;
}*/

.italic{
  font-style: italic;
}
.bold{
  font-weight: bold;
}
.underline{
  text-decoration: underline;
}
.textcenter{
  text-align: center !important;
}
.linkColor{
  color : $positive;
}

em,i{
  font-style: italic;
}

.decimal {
  list-style-type: decimal;
  margin-left: 10px;
}

.bullet {
  list-style-type: disc;
  margin-left: 10px;
}

.home-menu-buttons{
padding-top:15%;
  width: 90% !important;
  margin: 0 auto;
}

.bar-pink{
  height: 46px;
  margin-top:10px;
  position:relative;
  border-radius: 5px;
  color:white !important;

  text-align: center;
  vertical-align: middle;

}

.bar-pink{
  padding-top: 4px;
  color:white !important
}

.bar-pink h5{
  color:white !important;
  font-size:17px;
}

.title.big{
  height:100%;
  font-family: 'avenirnextregular';
  font-weight:500;
  color:white !important;
  font-size: 17px;
  top:-5px;
}

.title.solo{
  height:100%;
  font-family: 'avenirnextregular';
  font-weight:500;
  color:white !important;
  font-size: 17px;
}
.title{
  color:white !important;
  vertical-align: middle;
}
.title.small{
  height:100%;
  font-family: 'avenirnextregular';
  font-weight:500;
  color:white !important;
  font-size: 11px;
  top:13px;
}

.bar-grey{
  height: 46px;
  margin-top:10px;
  position:relative;
  border-radius: 5px;
  font-weight:bold !important;
  color:white !important;
  background-color:#bbbbbb;
  font-family: 'avenirnextregular';
}



.list .item.item-accordion {
  line-height: 19px;
  padding-top: 2vh;
  padding-bottom: 2vh !important;
  transition: 0.09s all linear;
}
.list .item.item-accordion.ng-hide {
  line-height: 0px;
}
.list .item.item-accordion.ng-hide-add,
.list .item.item-accordion.ng-hide-remove {
  display: block !important;
}

.center-icon{
  right:10px;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

input {
  user-select:auto;
  -webkit-user-select:auto;
  -moz-user-select: auto;
}
.ThTd{

  table-layout:fixed;

  min-width:160px;
  max-width:160px;
}
.tableHeadScroll{
  position: -webkit-sticky;
  position: sticky;
  top: 0;z-index: 5;
  background: #fff;
  display:block;
  table-layout:fixed;
  white-space: nowrap;

}

.popup-div{
  margin: 0px;
  padding: 0px;
  width: 0px;
  white-space: nowrap;
}
.popup-button{
    background-color: inherit;
    border: 0px solid;
    font-size: inherit;
    font-weight: initial;
    text-decoration: inherit;
    margin: 0px;
    padding: 0px;
    color: #D62752;
}
div .popup{
  width:90% !important;
}
.popup-container{
  width:100%;
}
/*
.score-button-floating {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 63px;
  margin-right: 20px;
  heigth: 40px;
  -webkit-border-radius:100px;
  -moz-border-radius:100px;
  border-radius: 100px;
  -webkit-box-shadow: 1px 4px 6px 0px rgba(0,0,0,0.61);
  -moz-box-shadow: 1px 4px 6px 0px rgba(0,0,0,0.61);
  box-shadow: 1px 4px 6px 0px rgba(0,0,0,0.61);

}*/

.sst-zoom-pane {

  width: 100%;
  height: 100%;

  .scroll {
    min-height: 100%;
    /* Vertical align */
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  img {
    width: 100%;
    vertical-align: middle;
  }
}

.sst-criterion-radio {
  padding: 0;
  border: none;
  border-right: 1px solid #ccc !important;
  text-align: center;

  &.item-radio .item-content {
    padding-right: 16px !important;
  }
}

.sst-criteria-eligibility {
  .item {
    line-height: 19px;
    padding-top: 3;
    padding-bottom: 3;
    transition: 0.09s all linear;
  }
  .item.ng-hide {
    line-height: 0px;
  }
  .item.ng-hide-add,
  .item.ng-hide-remove {
    display: block !important;
  }
}

.sst-eligible-background {
  background-color: #E0FFE0 !important;
}
.sst-ineligible-background {
  background-color: #FFE8E8 !important;
}
.sosVodTables{
  td{
    font-size: 85%;
    border: none !important; 
    background-color: #ffffff;
    margin: 2px;
    padding-left: 0px; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
    padding-bottom: 5px;
  }
  font-size: 110%;
}